<template>
	<div class="tab-card pa-3 mb-3 border tab-custom-data" :class="open_details ? 'border-ccc' : ''">
		<div
			@click="/* open_details = !open_details */"
			:class="open_details ? 'pb-2 border-bottom' : ''"
		>
			<div class="d-flex">
				<div class="" v-if="meeting">
					<div>
						<!-- <v-icon link class="mmr-2">{{ open_details ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon> -->
						<!-- <span class="fw-600">Meeting</span>
						for  -->
						<span
							class="fw-600 blue--text text--darken-4 mr-2 cursor-pointer"
							@click="goToDetail(meeting.id)"
							>{{ meeting.title }}
						</span>
						<!-- <v-chip
							class="mb-1 mr-1 fw-500"
							label
							color="blue white--text"
							x-small
							v-for="(staff, index) in meeting.attendies"
							:key="index"
						>
							{{ staff.name }}
						</v-chip> -->
						<!-- <template v-if="this.meeting && this.meeting.barcode">
							<v-chip
								class="mr-1 text-capitalize"
								color="cyan white--text"
								x-small
								v-if="this.meeting && this.meeting.barcode"
								>{{ this.meeting.barcode }}
							</v-chip>
						</template> -->
						<!-- <template v-if="meeting && meeting.attendies">
							<v-chip left small v-for="(staff, index) in meeting.attendies" :key="index">
								<v-avatar left small>
									<img v-if="staff.image" :src="staff.image" />
									<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
								</v-avatar>
								<span class="fw-500"> {{ staff.display_name }}</span>
							</v-chip>
						</template> -->
						<template v-if="meeting && meeting.related_to">
							<v-chip class="mb-1 mr-1 fw-500" label color="red white--text" x-small>
								{{ meeting.related_to }}
							</v-chip>
						</template>
						<v-chip
							class="mb-1 mr-1"
							label
							:color="`${getStatusColor(meeting.status)} white--text`"
							x-small
						>
							{{ getStatusText(meeting.status) }}
						</v-chip>
					</div>
					<div>
						<template v-if="meeting && meeting.attendies">
							<v-chip
								class="mr-1 pl-0"
								left
								small
								v-for="(staff, index) in meeting.attendies"
								:key="index"
							>
								<v-avatar class="pa-1" small>
									<img v-if="staff.image" :src="staff.image" />
									<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
								</v-avatar>
								<span class="fw-500"> {{ staff.display_name }}</span>
							</v-chip>
						</template>
					</div>
				</div>
				<v-spacer></v-spacer>
				<v-tooltip top content-class="custom-top-tooltip">
					<template v-slot:activator="{ on, attrs }" v-if="!meeting">
						<v-chip
							v-on="on"
							v-bind="attrs"
							class="mb-1 mr-1 fw-500"
							label
							color="orange white--text"
							x-small
						>
							Upcoming
						</v-chip>
					</template>
					<span>Status</span>
				</v-tooltip>
				<v-spacer></v-spacer>
				<div>
					<div class="date d-flex align-items-center">
						<v-menu
							offset-y
							left
							content-class="custom-menu"
							max-width="130px"
							v-if="indexMeeting > 0 || (meeting.status != 1 && meeting.status != 2)"
						>
							<template v-slot:activator="{ on, attrs }">
								<div class="primary--text d-flex align-center" v-bind="attrs" v-on="on">
									<span class="fs-500 fw-600">Actions </span>
									<v-icon link class="mr-2 primary--text">mdi-menu-down</v-icon>
								</div>
							</template>
							<v-list tile>
								<v-list-item-group>
									<v-list-item v-if="indexMeeting > 0">
										<v-list-item-title v-on:click="meetingPinned(meeting.id)">Pin</v-list-item-title>
									</v-list-item>
									<v-list-item v-if="meeting.status != 1 && meeting.status != 2">
										<v-list-item-title v-on:click="meetingEdit(meeting.id, 'Update')">Edit</v-list-item-title>
									</v-list-item>
									<!-- <v-list-item>
										<v-list-item-title v-on:click="meetingDelete(meeting)">Delete</v-list-item-title>
									</v-list-item> -->
								</v-list-item-group>
							</v-list>
						</v-menu>
						<v-icon class="mdi-16px mr-1 primary--text">mdi-calendar-clock-outline</v-icon>
						<span class="fw-600 text-no-wrap"
							>Date :
							<span v-if="meeting && meeting.start_from"
								>{{ formatDate(meeting.start_from) }} {{ meeting.start_time }}</span
							>
						</span>
					</div>
					<div class="text-right">
						<span v-if="meeting && meeting.meeting_type">
							<template v-if="meeting && meeting.meeting_type == 'zoom'">
								<v-chip
									class="ml-2 text-capitalize"
									color="blue white--text"
									outlined
									x-small
									label
									v-if="meeting && meeting.meeting_type"
									>{{ meeting.meeting_type }}
								</v-chip>
							</template>
							<template v-if="meeting && meeting.meeting_type == 'bthrust'">
								<v-chip
									class="ml-2 text-capitalize"
									color="red white--text"
									x-small
									outlined
									label
									v-if="meeting && meeting.meeting_type"
									>F2F Bthrust
								</v-chip>
							</template>
							<template v-if="meeting && meeting.meeting_type == 'client'">
								<v-chip
									class="ml-2 text-capitalize"
									color="green white--text"
									outlined
									x-small
									label
									v-if="meeting && meeting.meeting_type"
									>F2F Client
								</v-chip>
							</template>
							<template v-if="meeting && meeting.meeting_type == 'other'">
								<v-chip
									class="ml-2 text-capitalize"
									color="orange white--text"
									x-small
									outlined
									label
									v-if="meeting && meeting.meeting_type"
									>Other
								</v-chip>
							</template>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="open_details" v-on:click="goToDetail(meeting.id)">
			<div class="d-flex">
				<div class="w-70">
					<div class="py-2 text-h6">
						<span class="fw-600 mr-2">Title :</span>

						<span v-if="meeting && meeting.title">{{ meeting.title }}</span>
					</div>
					<div class="pb-2">
						<span class="fw-600 mr-2">Start : </span>
						<span v-if="meeting && meeting.start_from && meeting.start_time"
							>{{ formatDate(meeting.start_from) }} <b>{{ meeting.start_time }}</b></span
						>
					</div>
					<div class="pb-2">
						<span class="fw-600 mr-2">End : </span>
						<span v-if="meeting && meeting.end_to && meeting.end_time"
							>{{ formatDate(meeting.end_to) }} <b>{{ meeting.end_time }}</b></span
						>
					</div>
					<div class="pb-2">
						<span class="fw-600 mr-2">Duration : </span>
						<span v-if="meeting && meeting.duration"> {{ meeting.duration }}</span>
					</div>
					<div class="pb-2" v-if="meeting.meeting_type != 'zoom'">
						<span class="fw-600 mr-2">Location : </span>
						<span v-if="meeting && meeting.location">{{ meeting.location }}</span>
						<span v-if="meeting && meeting.loaction_id" class="text-capitalize">
							<span v-if="meeting.address1">{{ meeting.address1 ,}}</span>
							<span v-if="meeting.address2">{{ meeting.address2 ,}}</span
							><span v-if="meeting.zip_code">{{ meeting.zip_code ,}}</span> {{ meeting.country,}}</span
						>
					</div>
					<div class="pb-2 d-flex">
						<span class="fw-600 mr-2 text-no-wrap" style="width: 100px">Description : </span>
						<div v-if="meeting && meeting.description" v-html="meeting.description"></div>
					</div>
				</div>
				<div class="w-30" style="text-align: center">
					<div class="py-3">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<img
										v-on:click.stop.prevent="meetingUrl(meeting.host_url)"
										v-if="meeting.meeting_type == 'zoom'"
										style="max-width: 150px"
										:src="$assetURL('media/project-logos/zoom.png')"
									/>
								</span>
							</template>
							<span>Zoom url</span>
						</v-tooltip>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<img
										v-if="meeting.meeting_type == 'client'"
										style="max-width: 150px"
										:src="$assetURL('media/project-logos/client.png')"
									/>
								</span>
							</template>
							<span>Face to face client office</span>
						</v-tooltip>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<img
										v-if="meeting.meeting_type == 'bthrust'"
										style="max-width: 150px"
										:src="$assetURL('media/project-logos/bthrust.png')"
									/>
								</span>
							</template>
							<span>Face to face Bthrust</span>
						</v-tooltip>
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span v-on="on" v-bind="attrs">
									<img
										v-if="meeting.meeting_type == 'other'"
										style="max-width: 150px"
										:src="$assetURL('media/project-logos/meeting.png')"
									/>
								</span>
							</template>
							<span>Other Meeting</span>
						</v-tooltip>
					</div>
					<div class="text-center">
						<span v-if="meeting && meeting.meeting_type">
							<template v-if="meeting && meeting.meeting_type == 'zoom'">
								<v-chip
									class="ml-2 text-capitalize"
									color="blue white--text"
									outlined
									x-small
									label
									v-if="meeting && meeting.meeting_type"
									>{{ meeting.meeting_type }}
								</v-chip>
							</template>
							<template v-if="meeting && meeting.meeting_type == 'bthrust'">
								<v-chip
									class="ml-2 text-capitalize"
									color="red white--text"
									x-small
									outlined
									label
									v-if="meeting && meeting.meeting_type"
									>F2F Bthrust
								</v-chip>
							</template>
							<template v-if="meeting && meeting.meeting_type == 'client'">
								<v-chip
									class="ml-2 text-capitalize"
									color="green white--text"
									outlined
									x-small
									label
									v-if="meeting && meeting.meeting_type"
									>F2F Client
								</v-chip>
							</template>
							<template v-if="meeting && meeting.meeting_type == 'other'">
								<v-chip
									class="ml-2 text-capitalize"
									color="orange white--text"
									x-small
									outlined
									label
									v-if="meeting && meeting.meeting_type"
									>Other
								</v-chip>
							</template>
						</span>
					</div>
				</div>
			</div>
		</div>
		<DeleteTemplate
			type="Meeting"
			:deleteUrl="delURL"
			:deleteText="delTEXT"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="
				confirm_dialog = false;
				$emit('deleteMeeting', true);
			"
		></DeleteTemplate>
	</div>
</template>
<script>
import { PATCH } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";

export default {
	name: "MeetingTab",
	components: {
		DeleteTemplate,
	},
	props: {
		open_details: {
			type: Boolean,
			default: false,
		},
		meeting: {
			type: Object,
		},
		indexMeeting: {
			type: Number,
		},
	},
	data() {
		return {
			delURL: null,
			delTEXT: null,
			confirm_dialog: false,
		};
	},
	methods: {
		goToDetail(id) {
			this.$router.push({
				name: "meeting-detail",
				params: { id },
				query: { ...this.$route.query, t: new Date().getTime() },
			});
		},

		getStatusColor(status) {
			if (status == 1) {
				return "green";
			}
			if (status == 2) {
				return "red";
			}
			if (status == 3) {
				return "blue";
			}
			if (status == 4) {
				return "grey";
			}
			return "cyan";
		},
		getStatusText(status) {
			if (status == 1) {
				return "Completed";
			}
			if (status == 2) {
				return "Cancelled";
			}
			if (status == 3) {
				return "Postponed";
			}
			if (status == 4) {
				return "Upcoming";
			}
		},
		meetingUrl(url) {
			window.open(url, "_blank");
		},
		meetingDelete(row) {
			this.delTEXT = row.title;
			this.delURL = `meeting/${row.id}`;
			this.confirm_dialog = true;
		},
		meetingEdit(id, title) {
			this.$emit("editMeeting", id);
			this.$emit("editTitle", title);
			/* this.$emit("meetingSuccess", true); */
		},
		meetingPinned(id) {
			this.$store
				.dispatch(PATCH, { url: `pin-meeting/${id}` })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Meeting Pinned successfully." },
					]);
					this.$emit("deleteMeeting", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	beforeMount() {
		this.lead_id = this.$route.params.id;
	},
};
</script>
